@import url(https://fonts.googleapis.com/css?family=Noto+Sans+TC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@900&display=swap);
@font-face {
	font-family: 'SF Pro Display';
	font-weight: 500;
	src: local('SF Pro Display'), url(/static/media/FontsFree-Net-SFProDisplay-Medium-1.70830fa4.ttf) format('opentype');
}

body {
	margin: 0;
	font-family: "Noto Sans TC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

